import {getLogoHtml} from "../blockUtils";
import {unescapeHtml} from "../../../../utils/StringUtilities";
import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import ErrorBlock from "../ErrorBlock";
import {buildCleverFeedUrl, getCdnUrl, isArray, isSchoolFeedStandalone} from "../../../../utils/SchoolBlocksUtilities";
import ClickableLink from "../../../utilities/ClickableLink";
import {IBlockComponentProps} from "../_Block";
import FontAwesome from "../../../utilities/FontAwesome";

function getTitle(block) {
    if (block.title && block.title !== 'block') {
        return block.title;
    } else if (block.component.settings?.title && block.component.settings.title !== 'block') {
        return block.component.settings.title;
    } else if (block.component.settings?.headerTitle && block.component.settings.headerTitle !== 'block') {
        return block.component.settings.headerTitle;
    } else if (block.title) {
        return block.title;
    }
    return null;
}

type IProcessedGroupSubBlock = Omit<IGroupSubBlock, "logo"> & {
    logo?: string | JSX.Element
}

function getSubBlocks(grid: IGroupSubBlock[]): IProcessedGroupSubBlock[] {
    return Array.from(grid.map((block: IGroupSubBlock) => {
        const branding = {
            color: "#FF6600",
            colorStyle: {},
            colorClass: "",
            ...block.branding,
        };

        switch (branding.color) {
            case "orgColorDark":
                branding.colorClass = "sb-organization-color-block-bg";
                break;

            case "orgColorLight":
                branding.colorClass = "sb-organization-color-block-font";
                break;

            default:
                branding.colorStyle = {"backgroundColor": branding.color, "color": "#fff"};
        }

        const returnBlock: IProcessedGroupSubBlock = {
            ...block,
            ...block.model,
            title: block.title,
            branding,
        };

        returnBlock.title = getTitle(returnBlock);
        returnBlock.logo = getLogoHtml(returnBlock);

        return returnBlock;
    }))
}

interface IGroupBlockProps extends IBlockComponentProps {
    blockObj: IGroupBlockObj
}

export default function GroupBlock(props:IGroupBlockProps) {
    try {
        const blockObj = props.blockObj;

        let subBlockArray: IGroupSubBlock[] = [];
        if (isArray(blockObj.subBlocks)) {
            subBlockArray = blockObj.subBlocks as IGroupSubBlock[];
        } else if (blockObj.subBlocks) {
            subBlockArray = Object.values(blockObj.subBlocks);
        }

        const subBlocks: IProcessedGroupSubBlock[] = getSubBlocks(subBlockArray);
        return (
            <ClickableLink className="sb-block-link" href={isSchoolFeedStandalone() ? buildCleverFeedUrl(blockObj.id) : blockObj.groupUrl}>
                <BlockScroller className="sb-organization-color-block-font"
                               blockRef={props.blockRef}
                               blockObj={props.blockObj}
                               htmlId={props.htmlId}
                               setShowExpandButton={props.setShowExpandButton}
                               outsideGrid={props.outsideGrid}>
                    <BlockHead blockId={blockObj.id} blockType={blockObj.blockType}>
                        <span>
                            {blockObj.blockModel.is_private &&
                                <FontAwesome prefix={'fas'} name="fa-lock" title="This is a private group" style={{"marginRight": "0.5em"}}/>}
                            {!blockObj.blockModel.published &&
                                <FontAwesome prefix={'fas'} name="fa-pencil-alt" title="This is a Draft page" style={{"marginRight": "0.5em"}}/>}
                            {blockObj.blockModel.title ? unescapeHtml(blockObj.blockModel.title) : "Group"}
                        </span>
                    </BlockHead>
                    <div className="sb-blockContent">
                        {blockObj.picture ? <img style={{"height": "100%"}} src={getCdnUrl(blockObj.picture)} alt={blockObj.picture}
                                                 title={blockObj.picture}/>:<ul className="sb-section-list">
                                                     {subBlocks.map(block => {
                                let logoOrImage;
                                if (!block.picture) {
                                    if (block.branding?.logo?.fontawesome) {
                                        // this is here because the fontawesome logos are 2x when viewing a section and 1x in other areas.
                                        logoOrImage =
                                            <span className={`fa-2x ${block.branding.logo.fontawesome}`} role="img"
                                                  aria-label={block.branding.arialabel} />;
                                    } else {
                                        logoOrImage = block.logo;
                                    }
                                } else {
                                    logoOrImage = <img style={{"height": "100%"}} src={block.picture} alt={block.title}
                                                       title={block.title}/>;
                                }

                                return <li key={block.model.id}>

                                    <div className="clearfix">
                                        <div className={`sb-section-item-picture clearfix ${block.branding.colorClass}`}
                                             style={block.branding.colorStyle}>
                                            {logoOrImage}
                                        </div>
                                        <span>{unescapeHtml(block.title)}</span>
                                    </div>
                                </li>
                            })}
                                                 </ul>}
                    </div>
                </BlockScroller>
                {blockObj.school &&
                    <div className="sb-type-footer" style={{
                    "position": "absolute",
                    "bottom": 0,
                    "left": 0,
                    "right": 0,
                    "height": "3em",
                    "padding": "0.75em",
                    }}>
                        <p className="sb-footer-title">{blockObj.school.title}</p>
                    </div>}
            </ClickableLink>
        )
    } catch (_e: unknown) {
        const e: Error = _e as Error;
        return <ErrorBlock blockObj={{errorMessage: e}} />
    }
}
